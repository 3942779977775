<template>
  <div class="card">
    <div class="card-body">

      <package-features v-if="order.product_id" :item="order.package"
      :attributes="order.attributes" class="mb-4" />

      <plan-features v-if="order.task_id" :item="order.plan"
      :attributes="order.attributes" class="mb-4" />

      <addons v-if="order.product_id" :addons="cart.addons" class="mb-4" />

      <div class="">
        <requirement-list />
      </div>
            
    </div>
  </div>
</template>

<script>
import RequirementList from "./RequirementList.vue"
import Addons from "./Addons.vue"
import PackageFeatures from '@/components/order/PackageFeatures.vue'
import PlanFeatures from '@/components/order/PlanFeatures.vue'

export default {
  components: {
    RequirementList,
    Addons,
    PackageFeatures,
    PlanFeatures
  },
  data() {
    return {
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.order.package
    },
    item(){
      return this.order.product || this.order.item
    },
  },
}

</script>

<style type="text/css" scoped>

    sub{font-size: 12px;}

    @media(max-width: 580px){
      .order-details-header{
        margin-bottom: 1.5em;

      }
    }
</style>
