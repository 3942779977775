

<template>
  <div>
    <div class="">
      <h4 class="mb-0 text-primary">Buyer filled requirements</h4>
      <hr class="mt-2">
      <div class="">
        <div  v-for="(requirement, rIndex) of requirements" :key="rIndex" class="mb-3 bg-light p-2 px-lg-3 position-relative">
          <!-- <span style="left:0;" class="position-absolute">{{rIndex+1}}</span> -->
          <div v-if="['pro_input','pro_select','pro_textarea'].includes(requirement.type)">
              <h5 class="mb-1">{{requirement.title}}</h5>
              <p>{{requirement.answer}}</p>
          </div>
          <div v-else-if="requirement.type == 'photo_gallery'" class="">
              <h5 class="mb-1">{{requirement.title}}</h5>
              <div class="row mb-3">
                  <div v-for="(photoType, ptIndex) of requirement.answer" :key="ptIndex"
                  class="col-md-4 col-lg-3 col-6 text-center">
                      <photo-gallery-card :photoType="photoType"/>
                  </div>
              </div>
          </div>
          <div v-else-if="requirement.type == 'color_gallery'" class="" >
              <h5 class="mb-1">{{requirement.title}}</h5>
              <div class="row mb-3">
                  <div v-for="(colorType, clIndex) in requirement.answer" :key="clIndex"
                  class="col-md-4 col-lg-3 col-6 text-center">
                      <color-gallery-card :colorType="colorType"/>
                  </div>
              </div>
          </div>
          <div v-else-if="requirement.type == 'pro_checkbox'" class="" >
              <h5 class="mb-1">{{requirement.title}}</h5>
              <div v-if="requirement.answer">
                  {{requirement.answer.map((i)=> i.name).join(", ")}}
              </div>
          </div>
          <div v-else-if="requirement.type == 'file_uploader'" class="" >
              <h5 class="mb-1">{{requirement.title}}</h5>
              <order-attachments columnClass="col-md-4 col-lg-3 mb-3" v-if="requirement.answer && requirement.answer.length"
                              :files="requirement.answer" />
          </div>
          <div v-else>
              <h5 class="mb-1">{{requirement.title}}</h5>
              <p>{{requirement.answer ? requirement.answer : "none" }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <hr class=""/>
    <div class="row">
      <div class="col-12">
          <h4 class="font-weight-bold">Attached Files</h4>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3 mb-4" v-for="(file, index) in files" :key="index">
        <div v-if="isImageFile(file.extension)" class="">
          <b-img thumbnail class="mr-3" fluid :src="absoluteUrl(file.path)" :alt="file.name"></b-img>
        </div>
        <a :href="absoluteUrl(`/download?file_path=${file.path}`)"
            :download="file.name" style="color:inherit;" class="downloadable">
          <div>
            <h6 class="mb-0">{{file.name}}</h6>
            <span class="mr-2 "> {{file.size}} ({{file.extension}}) </span>
          </div>
        </a>
      </div>
    </div> -->
  </div>
</template>

<script>
import PhotoGalleryCard from '@/components/cards/PhotoGalleryCard.vue'
import ColorGalleryCard from '@/components/cards/ColorGalleryCard.vue'
import OrderAttachments from './OrderAttachments.vue'

export default {
  components: { 
    PhotoGalleryCard,
    ColorGalleryCard,
    OrderAttachments
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    requirements(){
      return this.order.cart.requirements
    },
    // files(){
    //   return this.order.sample_files
    // },
  }
}
</script>


<style scoped>
.color-types .color-type-block{
    height: 45px;
}

@media(max-width:567px){
    .color-types .color-type-block{
        height: 40px;
    }
}
</style>